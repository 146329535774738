import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'

@Component({
  name: 'GtrRegistrationModulePromoCodesView',
  computed: {
    ...mapState('promocodes', ['promocodes'])
  }
})
export default class GtrRegistrationModulePromoCodesView extends GtrSuper {
  data () {
    return {
      search: '',
      loading: false,
      headers: [
        {
          text: 'Promo Code',
          align: 'center',
          value: 'code'
        },
        { text: 'Discount', align: 'center', value: 'discount_type' },
        { text: 'Active Start Date', align: 'center', value: 'active_start_date' },
        { text: 'Active End Date', align: 'center', value: 'active_end_date' },
        { text: 'Used', align: 'center', value: 'count' },
        { text: 'Cap', align: 'center', value: 'cap' },
        { text: 'Reg Types', align: 'center', value: 'reg_types', sortable: false },
        { text: '', align: 'center', value: 'controls', sortable: false }
      ],
      promoCodes: [],
      promoToDelete: null,
      options: {
        itemsPerPage: 100,
        page: 1
      }
    }
  }

  get matchingPromocodes () {
    if (this.$data.search !== '') {
      return this.$data.promoCodes.filter((code: any) => {
        return code.code.toLowerCase().includes(this.$data.search.toLowerCase())
      })
    } else {
      return this.$data.promoCodes
    }
  }

  async mounted () {
    await this.fetchPromoCodes()
    await this.fetchOptionGroups()
  }

  async exportAllPromoCodes () {
    this.$data.loading = true
    const response = await this.$store.dispatch('promocodes/exportAllPromoCodes', this.$route.params.event_uuid)
    this.$data.loading = false
    this.checkURLAndGo(response.data)
  }

  async exportSinglePromoCode (promo_code_uuid: string) {
    this.$data.loading = true
    const payload = {
      promo_code_uuid,
      event_uuid: this.$route.params.event_uuid
    }
    const response = await this.$store.dispatch('promocodes/exportSinglePromoCode', payload)
    this.$data.loading = false
    this.checkURLAndGo(response.data)
  }

  @Watch('promocodes')
  onPromoCodesChange (payload: any) {
    if (payload.data && Array.isArray(payload.data)) {
      this.$data.promoCodes = payload.data
    }
  }

  handleDeletePromoCode (payload: any) {
    this.$data.promoToDelete = payload
  }

  async onDeletePromoCodeAction (payload: any) {
    if (payload.confirm) {
      try {
        this.$data.loading = true
        const data: any = {
          event_uuid: this.$route.params.event_uuid,
          promocode_uuid: this.$data.promoToDelete.uuid
        }
        await this.$store.dispatch('promocodes/deletePromoCode', data)
        Container.get(Notification).success('Promo code successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }
    this.$data.promoToDelete = null
  }

  private async fetchPromoCodes () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('promocodes/getPromoCodes', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchOptionGroups () {
    try {
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  get registration_types () {
    const option_groups = this.$store.state.option.option_groups
    if (Object.keys(option_groups).length === 0) {
      return []
    }
    const registration_types_array = option_groups
      .filter(group => group.name === 'Registration Types')
      .shift().options
    const registration_types: any = []
    for (let i = 0; i < registration_types_array.length; i++) {
      registration_types.push({
        text: registration_types_array[i].name,
        value: registration_types_array[i].uuid
      })
    }
    registration_types.push({
      text: 'Default',
      value: '_default'
    })
    return registration_types
  }

  regTypeName (value: string): string {
    const [registrationType] = (this.registration_types || []).filter(registrationType => registrationType.value === value)
    return registrationType?.text
  }
}
